import * as React from 'react';

import AttributeGrid from '../components/AttributeGrid';
import Container from '../components/Container';
import Hero from '../components/Hero';
import BlogPreviewGrid from '../components/BlogPreviewGrid';
import Highlight from '../components/Highlight';
import Layout from '../components/Layout/Layout';
import ProductCollectionGrid from '../components/ProductCollectionGrid';
import ProductCardGrid from '../components/ProductCardGrid';
import Quote from '../components/Quote';
import Title from '../components/Title';

import { generateMockBlogData, generateMockProductData } from '../helpers/mock';

import * as styles from './index.module.css';
import { Link, navigate } from 'gatsby';

const IndexPage = () => {
  return (
    <Layout disablePaddingBottom>
      
      {/* Message Container */}
      <div className={styles.messageContainer}>
        <p>
          Avea {' '}
          <span className={styles.gold}>Media</span>
          . {' '}
        </p>
      </div>

      {/* Highlight  */}
      <div className={styles.highlightContainer}>
        <Container size={'large'} fullMobile>
          <Highlight
            image={'/highlight.png'}
            altImage={'highlight image'}
            miniImage={'/highlightmin.png'}
            miniImageAlt={'mini highlight image'}
            title={'OpenVerify.com'}
            description={`Domain branding for social media, coms, and identity`}
            textLink={'visit'}
            link={'https://www.openverify.com'}
          />
        </Container>
      </div>

      <div className={styles.highlightContainer}>
      <Container size={'large'} fullMobile>
        <Highlight
          image={'/highlight2.png'}
          altImage={'highlight image'}
          miniImage={'/highlightmin2.png'}
          miniImageAlt={'mini highlight image'}
          title={'ArtOfAuto.com'}
          description={`Bespoke AI generated motorsports 3D printed parts and software`}
          textLink={'visit'}
          link={'https://www.artofauto.com'}
        />
      </Container>
    </div>
    </Layout>
  );
};

export default IndexPage;
